import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

const CustomPage = () => {
  return (
    <Layout>
      <main role="main" className="bg-white">
        <div className="relative tailor-cover">
          <div className="pv6 mt0 center mw9 ph3">
            <h2 className="relative z-1 dib white frank ttu fw4 f4 mv4 tracked-mega">
              Un portale personalizzato è il tuo miglior biglietto da visita
            </h2>
            <h1 className="relative z-1 pb3 playfair f2 f1-ns fw4 white">
              Il tuo stile è unico
              <br />
              come il tuo portale
            </h1>
            <h2 className="relative z-1 dib white frank fw3 f2">
              Fai sentire a casa i tuoi Clienti
            </h2>
          </div>
        </div>

        <div className="" style={{ backgroundColor: "#EDEDED" }}>
          <div
            className="relative personality-cover mw9 center w-100"
            style={{ height: "50rem" }}
          ></div>
        </div>

        <div className="mw9 center pa4">
          <h2
            className="f2 f1-ns playfair fw4 tc pv2"
            style={{ marginBottom: "1rem" }}
          >
            Fai spazio alla tua personalità
          </h2>
          <p className="center mw8 frank fw4 f3 mv4 lh-copy">
            Con un account Premium puoi personalizzare il tuo portale secondo le
            tue esigenze. Puoi scegliere i font del portale, personalizzare i
            testi nelle email e aggiungere il tuo logo. Così il tuo portale si
            adatterà perfettamente al tuo sito già esistente, per feeling unico.
          </p>
        </div>

        <div className="mw9 center mv6 pa4 bg-light-color">
          <div className="flex flex-wrap-reverse mv6">
            <div className="w-100 w-50-ns ph3">
              <h4 className="dn di-ns fw4 mv0 f1 playfair">
                Il tuo spazio personale
              </h4>
              <div className="mv4 pv3 bb">
                <h6 className="mv0 frank fw4 f3 ttu tracked">
                  Un sottodominio personalizzato
                </h6>
              </div>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                Con ogni account si ha la possibilità di scegliere un
                sottodominio, come ad esempio{" "}
                <span className="lato black-60">
                  studiolegale.time2client.com
                </span>
                .
              </p>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                Tutti i tuoi clienti avranno il tuo sottodominio come indirizzo
                di ingresso al tuo portale.
              </p>
              <div className="mv4 pv3 bb">
                <h6 className="mv0 frank fw4 f3 ttu tracked">
                  Il tuo logo e font
                </h6>
              </div>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                Personalizza il tuo spazio caricando il tuo logo personale. Puoi
                scegliere tra diversi temi che daranno al tuo portale un impatto
                unico.
              </p>
              <div className="mv4 pv3 bb">
                <h6 className="mv0 frank fw4 f3 ttu tracked">
                  I tuoi colori e immagini
                </h6>
              </div>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                Tutti i colori di ogni tema sono personalizzabili, così come le
                immagini di sfondo. In questo modo puoi impostare e creare il
                set perfetto in linea con i tuoi gusti, oppure caricare la foto
                del tuo studio o della tua città
              </p>
              <div className="mv4 pv3 bb">
                <h6 className="mv0 frank fw4 f3 ttu tracked">
                  Facile da integrare nel tuo sito
                </h6>
              </div>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                Quando hai la possibilità di personalizzare in questo modo il
                tuo portale, integrarlo nel tuo sito personale è più facile.
                Crea facilmente un link nel tuo sito personale.
              </p>
              <p className="pl3 mv3 frank fw4 f4 measure lh-copy">
                E nel caso tu non sappia come farlo, abbia bisogno di aiuto, o
                voglia suggerire nuove integrazioni o funzionalità puoi sempre
                contattarci.
              </p>
            </div>
            <div className="w-100 w-50-ns">
              <img
                src="/images/logins/industrial.png"
                className="shadow-5"
                alt=""
              />
              <img
                src="/images/logins/classic.png"
                className="shadow-5"
                alt=""
              />
              <img
                src="/images/logins/modern.png"
                className="shadow-5"
                alt=""
              />
            </div>
            <h4
              className="db dn-ns center fw4 f2 playfair"
              style={{ marginBottom: "1rem" }}
            >
              Il tuo spazio personale
            </h4>
          </div>
        </div>

        <div className="bg-black">
          <div className="mw9 center flex flex-wrap pt6">
            <div className="w-100 w-50-ns ph3 pb6-m pb3">
              <h4 className="db db-m fw4 f1 white playfair mv0">
                Infinite possibilità
              </h4>
              <p className="mt4 frank white fw4 f3 measure lh-copy">
                Se non trovi il tema giusto per puoi crearne uno tuo o
                contattarci per maggiori informazioni.
              </p>
              <Link
                className="tc ttu frank f3 fw3 mv5 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
                to="/contacts"
                style={{ width: "18rem" }}
              >
                Contattaci
              </Link>{" "}
            </div>
            <div className="w-100 w-50-ns">
              <img src="/images/rubik.jpg" className="db center w-100" alt="" />
            </div>
          </div>
        </div>

        <div
          className="relative pv5 bg-light-color footer-cover"
          style={{ backgroundColor: "#2b2928" }}
        >
          <div className="mw9 pb5 ph3 tc-ns center">
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Time2Client è in fase di anteprima e disponibile solo su invito.
            </p>
            <p className="white f4 f3-m f2-l mv0 pv0 playfair">
              Se vuoi essere tra i primi Clienti, inserisci il tuo indizzo mail
              qui sotto.
            </p>
          </div>
          <form
            action="/it/registration/interest"
            className="bg-sec-color white mw7 center pa4 br2-ns ba b--black-10"
            method="post"
          >
            <input
              name="_csrf_token"
              type="hidden"
              value="WmQTZCNhXVIhGVVbXxwfLBkoYC4bFkEM54fWsQp1WHlhfLuHrpSfxNv4"
            />
            <fieldset className="cf bn ma0 pa0">
              <legend className="pa0 f5 f4-ns mb3">
                Voglio provare Time2Client
              </legend>
              <div className="cf">
                <label className="clip" htmlFor="lawyer_email">
                  Email
                </label>
                <input
                  className="f6 f5-l input-reset bn fl black-80 bg-white pa3 lh-solid w-100 w-75-m w-80-l br2-ns br--left-ns"
                  id="lawyer_email"
                  name="lawyer[email]"
                  placeholder="avvocato@studiolegale.it"
                  type="email"
                />
                <button
                  className="frank f5 f4-l button-reset fl pv3 tc bn bg-animate bg-black-70 hover-bg-prim-color white pointer w-100 w-25-m w-20-l br2-ns br--right-ns"
                  type="submit"
                >
                  Invia
                </button>
              </div>
              <p className="lato f5 pt3">Tienimi informato</p>
            </fieldset>
          </form>
        </div>
      </main>
    </Layout>
  )
}

export default CustomPage
